<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <!-- 設定済コンテンツ一覧 -->
        運営からのお知らせ
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        id="info_table"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :items-per-page="10"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-options': [10, 50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- NEW表示 -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.new="{ item }">
          <div class="new_anime">{{ item.new }}</div>
        </template>
        <template v-slot:no-data> 現在、お知らせはありません </template>
      </v-data-table>
      <div class="text-subtitle-2 mt-2 pa-1 orange--text orange lighten-5">
        【注意事項】<br />通信エラー等で処理が停止した際は、ブラウザの再読み込み、又は、F5キーを押して、ページを再読み込みして下さい。
      </div>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import modelShopSite from "@/model/shopSite.js";

export default {
  setup(props, ctx) {
    const informationsRepository = repositoryFactory.get("informations");
    const state = reactive({
      search: "",
      headers: [
        {
          text: "",
          value: "new",
          align: "center",
          width: 30,
          sortable: false,
        },
        {
          text: "日付",
          value: "date",
          align: "center",
          width: 100,
        },
        {
          text: "タイトル",
          value: "title",
          sortable: false,
          width: 250,
        },
        {
          text: "コメント",
          value: "content",
          sortable: false,
          class: "td_content",
        },
      ],
      desserts: [],
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // 更新媒体を取得する
      await modelShopSite.setStore();

      // お知らせ
      const checkDate = new Date();
      checkDate.setDate(checkDate.getDate() - 7);
      state.desserts.splice(0);
      await informationsRepository
        .list()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const new_text =
                utilDate.getDateFormatLong(checkDate) < response.data[key].date
                  ? "NEW"
                  : "";
              state.desserts.push({
                "new": new_text,
                "id": response.data[key].id,
                "date": response.data[key].date,
                "title": response.data[key].title,
                "content": response.data[key].content,
                "type": response.data[key].type,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:home/index.vue/init informationsRepository.list (" +
            error +
            ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
::v-deep #info_table thead {
  background-color: #e3f2fd !important;
}
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_content {
  min-width: 250px;
}
.new_anime {
  color: red;
}
</style>
